import './factoid.scss'

const Factoid = ({ number, caption }) => (
  <figure className="factoid">
    <strong className="factoid__number">{ number }</strong>
    <figcaption className="factoid__caption">{ caption }</figcaption>
  </figure>
)

export default Factoid
