import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import Form from './form/form'
import Flags from './flags/flags'
import Columns from './columns/columns'
import { ParallaxProvider } from 'react-scroll-parallax'
import { setPageReady, initStickyLogo } from './helpers'
import Viewport from './viewport'

if (window.location.search.includes('stage')) document.body.classList.add('is__stage')

ReactDOM.render(<Form/>, document.getElementById('js__form'));
ReactDOM.render(<Columns/>, document.getElementById('js__columns'));

/* HACK: показываем флаги с задержкой, чтобы параллакс учёл высоту всех остальных компонентов */
setTimeout(() => {
  ReactDOM.render(<ParallaxProvider><Flags/></ParallaxProvider>, document.getElementById('js__flags'));
}, 250)


document.addEventListener('DOMContentLoaded', () => {
  initStickyLogo();
  setPageReady();

  new Viewport()
})
