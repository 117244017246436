import './factoids.scss'
import Factoid from '../factiod/factoid'

const FACTOIDS = [
  [
    { number: 4149, caption: 'троек опубликовано и 1105 утоплено'},
    { number: 126, caption: 'раз прислали самое популярное слово для белого — «элегантность»' },
    { number: 221, caption: 'раз для синего — «небо»' },
    { number: 113, caption: 'раз для красного — «кровь»' },
  ],
  [
    { number: 69, caption: 'троек со словом «водка»' },
    { number: 60, caption: 'троек со словом «борщ»' },
    { number: 19, caption: 'троек со словом «сметана»' },
    { number: 1, caption: 'тройка со словом «жопа»' },
  ]
]

const Factoids = () => (
  <div className="factoids">
    { FACTOIDS.map((factoidGroup, groupIndex) => (
      <div className="factoids__group">
        { groupIndex > 0 && <p className="factoids__divider">А ещё</p> }

        { factoidGroup.map(({ number, caption }, factoidIndex) => (
          <Factoid number={ number } caption={ caption } key={ factoidIndex }/>
        ))}
      </div>
    ))}
  </div>
)

export default Factoids
