import './column.scss'

import Phrase from '../phrase/phrase'

const Column = ({ title, phrases }) => (
  <div className="column">
    <h2 className="column__title">{ title }</h2>

    <div className="column__phrases">
      { phrases.map((phrase, key) => (
        <Phrase
          words={ phrase.words }
          slug={ phrase.slug }
          key={ key } />
      ))}
    </div>
  </div>
)

export default Column
