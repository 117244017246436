const MIN_VIEWPORT = 375

export default class Viewport {
  constructor() {
    this.$el = document.querySelector('meta[name="viewport"]')
    this.defaultValue = this.$el.getAttribute('content')

    this.setViewport()
    window.addEventListener('resize', this.setViewport.bind(this))
    window.addEventListener('orientationchange', this.setViewport.bind(this))
  }

  setViewport() {
    const isNarrowScreen = window.screen.width < MIN_VIEWPORT
    const content = isNarrowScreen ? `width=${MIN_VIEWPORT}` : this.defaultValue

    this.$el.setAttribute('content', content)
  }}
