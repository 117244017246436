import './submittedFlag.scss'
import React from 'react';
import Flag from '../flag/flag'
import ShareButton from '../shareButton/shareButton'
import copy from 'copy-to-clipboard'
import cx from 'classnames'

/**
 * Когда кликнули, ставим КЭН_КЛИР = 0, затем тупо считаем три секунды. Через три секунды ставим параметр КЭН КЛИР. если стоит шулдКлирВенКен, то чистим
 * На маусливе проверяем: если кэнклир чистим сразу, если нет — ставим шулдКлирВенКэн
 */
const CLEAN_TIMEOUT = 3000
const MIN_LAPTOP_WIDTH = 961

export default class SubmittedFlag extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isCopied: false,
      isPinned: false,
    }

    this.canClear = true
    this.shouldClearWhenItCan = false

    this.copyShareLink = this.copyShareLink.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  componentDidMount() {
    setTimeout(() => { this.setState({ isPinned: true }) }, 10)
  }

  onMouseLeave() {
    if (this.canClear) {
      this.clear()
    } else {
      this.shouldClearWhenItCan = true
    }
  }

  copyShareLink() {
    if (this.state.isCopied) return

    copy(`https://3slova.bureau.ru/${ this.props.slug }/`)

    this.setState({ isCopied: true })

    this.canClear = false

    setTimeout(() => {
      if (this.shouldClearWhenItCan || this.isOnMobile) this.clear()
      this.canClear = true
    }, CLEAN_TIMEOUT)
  }

  clear() {
    if (!this.state.isCopied) return

    this.setState({ isCopied: false })
    this.shouldClearWhenItCan = false
  }

  get isOnMobile() {
    return window.innerWidth < MIN_LAPTOP_WIDTH
  }

  render() {
    const { words, slug } = this.props
    const classNames = cx('submittedFlag', { is__pinned: this.state.isPinned })

    return (
      <div className={ classNames } onClick={ this.copyShareLink } onMouseLeave={ this.onMouseLeave }>
        <div className="submittedFlag__in">
          <Flag isSubmitted words={ words }/>
          <ShareButton slug={ slug } isCopied={ this.state.isCopied }/>
        </div>
      </div>
    )
  }
}
