import React from 'react';
import './input.scss';
import cx from 'classnames'

export default class Input extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      isValid: true,
      isOverflowed: false,
    }

    this.onChange = this.onChange.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  componentDidUpdate() {
    const inStyles = window.getComputedStyle(this.$in);
    const inWidth = this.$in.clientWidth - parseFloat(inStyles.paddingLeft) - parseFloat(inStyles.paddingRight)

    const inputWidth = this.$value ? this.$value.clientWidth : 0

    const isOverflowed = inputWidth > inWidth

    if (this.state.isOverflowed !== isOverflowed) this.setState({ isOverflowed })
  }

  clean() {
    this.setState({ value: '' })
  }

  focus() {
    this.$input.focus()
  }

  setInvalid() {
    if (this.state.isValid) {
      this.setState({ isValid: false })
      setTimeout(() => { this.setState({ isValid: true }) }, 1600)
    }
  }

  onChange(e) {
    const { value } = e.target

    this.setState({ value })

    if (this.props.onChange) this.props.onChange()
  }

  onFocus() {
    this.setState({ isFocused: true })
  }

  onBlur() {
    this.setState({ isFocused: false })
  }

  render() {
    const classNames = cx(
      'input',
      {
        is__focused: this.state.isFocused,
        is__filled: !!this.state.value,
        is__valid: this.state.isValid,
        is__overflowed: this.state.isOverflowed,
      })

    return (
      <div className={ classNames }>
        <input
          type="text"
          id={ `input-${this.props.index}` }
          name={ `word-${this.props.index}`}
          value={ this.state.value }
          onChange={ this.onChange }
          autoComplete="off"
          onFocus={ this.onFocus }
          onBlur={ this.onBlur }
          ref={ $input => this.$input = $input }
          spellCheck="false" />

        <div className="input__in" ref={ $in => this.$in = $in }>
          { !this.state.value &&
            <span className="input__placeholder">{ this.props.placeholder }</span>
          }

          { !!this.state.value &&
            <span className="input__value" ref={ $value => this.$value = $value }>{ this.state.value }</span>
          }
        </div>
      </div>
    )
  }
}
