import './flags.scss'

import React from 'react';
import Flag from '../flag/flag'
import { isDev } from '../helpers'

const MAX_FLAGS = 43

export default class Flags extends React.Component {
  constructor(props) {
    super(props)

    this.state = { flags: [] }

    this.fetchPhrases()
      .then(this.displayFlags.bind(this))
      .catch(e => {
        console.warn('failed fetching phrases', e)
      })
  }

  fetchPhrases() {
    if (isDev()) return Promise.resolve(require('./dummyFlags.json'))

    return fetch(`/phrases/?t=_${Date.now()}`)
      .then(res => res.json())
  }

  displayFlags(phrases) {
    const flags = phrases
      .map(({ word_1, word_2, word_3 }) => ({ words: [word_1, word_2, word_3] }))
      .slice(0, MAX_FLAGS)

    this.setState({ flags })
  }

  render() {
    const { flags } = this.state

    if (!flags) return null

    return (
      <div className='flags'>
        { flags.map(({ words }, index) => <Flag isParallaxed words={ words } key={ index }/>)}
      </div>
    )
  }
}
