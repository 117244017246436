import React from 'react';
import cx from 'classnames';
import './flag.scss';
import { Parallax } from 'react-scroll-parallax';

const SimpleWrapper = ({ children }) => <div>{ children }</div>

export default class Flag extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isReady: false }
  }

  componentDidMount() {
    setTimeout(() => { this.setState({ isReady: true })}, Math.floor(Math.random() * 500))
  }

  render() {
    const Wrapper = this.props.isParallaxed ? Parallax : SimpleWrapper;
    const classNames = cx('flag', { is__ready: this.state.isReady || this.props.isSubmitted })

    return (
      <div className={ classNames }>
        <Wrapper y={[ 30, -100 ]}>
          { [1, 2, 3].map(sectionNumber => (
            <div className="flag__section" key={ `section-${sectionNumber}` }>
              <div className="flag__sectionIn">
                  { this.props.words.map((word, index) => (
                    <span className="flag__row" key={ `row-${index}` }>{ word }</span>
                  )) }
              </div>
            </div>
          )) }
        </Wrapper>
    </div>
    )
  }
}
