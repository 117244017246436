import './shareButton.scss'
import React from 'react';
import { IconShareText, IconShareTextSuccess } from '../icons/icons'
import cx from 'classnames'

export default class ShareButton extends React.Component {
  render() {
    const classNames = cx('shareButton', { is__copied: this.props.isCopied, is__ready: !!this.props.slug })

    return (
      <div className={ classNames } onClick={ this.copyShareLink } onMouseLeave={ this.cleanShareButton }>
        <IconShareText/>
        <IconShareTextSuccess/>

        <span className="shareButton__caption">
          Вставьте ссылку в соцсети, письмо или чат
        </span>
      </div>
    )
  }
}
