import React from 'react'
import './columns.scss'
import { isDev } from '../helpers'
import Column from '../column/column'
import Factoids from '../factoids/factoids'

const MAX_TAGGED_COLUMNS = 10
const MAX_PHRASES_IN_COLUMN = 10

const COLUMN_TITLES = {
  recent: 'Свежее',
  selected: 'Выбор бюро',
}

const TAGS_ORDER = ['серьёзное', 'жизненное', 'питейное', 'кулинарное', 'исконное', 'рекламное', 'мемное', 'дизайнерское', 'отбитое', 'философское']

const sortTags = (tagA, tagB) => TAGS_ORDER.indexOf(tagA.title.toLowerCase()) - TAGS_ORDER.indexOf(tagB.title.toLowerCase())

const getBeautifiedPhrases = column => column
  .map(({ word_1, word_2, word_3, slug }) => ({ slug, words: [word_1, word_2, word_3] }))
  .slice(0, MAX_PHRASES_IN_COLUMN)

export default class Columns extends React.Component {
  constructor(props) {
    super(props)

    this.state = { columns: [] }

    this.fetch()
      .then(this.beautify)
      .then(this.display.bind(this))
      .catch(e => {
        console.warn('FAILED fetching phrases', e)
      })
  }

  fetch() {
    if (isDev()) return Promise.resolve(require('./dummyColumns.json'))

    return fetch(`/phrases/featured/?t=${Date.now()}`)
      .then(res => res.json())
  }

  beautify(columns) {
    const tags = Object.keys(columns.tagged)

    const featured = ['recent', 'selected'].map(key => ({
      title: COLUMN_TITLES[key],
      phrases: getBeautifiedPhrases(columns[key])
    }))

    const tagged = tags.map(tag => ({
      title: tag,
      phrases: getBeautifiedPhrases(columns.tagged[tag])
    }))
      .slice(0, MAX_TAGGED_COLUMNS)
      .sort(sortTags)

    return  { featured, tagged }
  }

  display(columns) {
    this.setState({ columns })
  }

  render() {
    return (
      <div className="columns">
        <div className="columns__in">
          { Object.keys(this.state.columns).map((key, rowKey) => (
            <div className={ `columns__row is__${key}` } key={ rowKey }>
              { key === 'tagged' && <Factoids /> }

              { this.state.columns[key].map((column, columnKey) => (
                <Column
                  title={ column.title }
                  phrases={ column.phrases }
                  key={ columnKey }/>
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  }
}
