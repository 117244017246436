import React from 'react';
import './form.scss';
import Input from '../input/input'
import ButtonSend from '../buttonSend/buttonSend'
import { isDev } from '../helpers'
import SubmittedFlag from '../submittedFlag/submittedFlag'

export default class Form extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      prevSubmittedWords: undefined,
      submittedWords: undefined,
      submittedSlug: undefined,
      isShareLinkCopied: false,
      isDirty: false,
      isSubmitted: false,
    }
    this.inputs = []

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    this.focusFirstEmptyInput();
  }

  focusFirstEmptyInput() {
    const firstEmptyInput = this.inputs.find(input => !input.state.value)
    if (firstEmptyInput) firstEmptyInput.focus()
  }

  reset() {
    this.setState({ isDirty: false })
    this.inputs.forEach(input => input.clean())
    if (document.activeElement) document.activeElement.blur()
  }

  onChange() {
    if (!this.state.isDirty) this.setState({ isDirty: true })
    if (this.state.isSubmitted) this.setState({ isSubmitted: false })
  }

  onSubmit(e) {
    e.preventDefault()

    if (!this.checkValidity()) return

    this.setState({
      prevSubmittedWords: this.state.submittedWords,
      submittedWords: this.words,
      isSubmitted: true
    }, this.reset)

    this.sendWords()
      .then(res => {
        this.setState({ submittedSlug: res.slug })
      })
      .catch(e => {
        console.warn('ERROR SENDING WORDS', e)
      })
  }

  checkValidity() {
    let isValid = true

    this.inputs.forEach(input => {
      if (isValid && !input.state.value) {
        input.setInvalid()
        isValid = false
      }
    })

    if (!isValid) this.focusFirstEmptyInput()

    return isValid
  }

  sendWords() {
    const [word_1, word_2, word_3] = this.words
    const wordsFlat = { word_1, word_2, word_3 }

    if (isDev()) return new Promise(resolve => setTimeout(() => { resolve({ ...wordsFlat, slug: 'BTydiMVT' }) }, 900))

    return fetch('/phrases/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(wordsFlat)
    })
      .then(res => res.json())
  }

  get words() {
    if (!this.$form) return []

    const formData = new FormData(this.$form)
    const result = []

    for (const word of formData.values()) result.push(word)

    return result
  }

  render() {
    const { isSubmitted, isDirty, submittedWords, submittedSlug } = this.state

    return (
      <form className="form" onSubmit={ this.onSubmit } ref={ $form => this.$form = $form } method="POST">

        { ['белый', 'синий', 'красный'].map((word, index) => (
          <Input
            ref={ input => this.inputs[index] = input }
            placeholder={ word }
            key={ word }
            index={ index }
            onChange={ this.onChange }/>
        )) }

        { (!isDirty && !!submittedWords) &&
          <SubmittedFlag words={ submittedWords } slug={ submittedSlug } />
        }

        <ButtonSend isSubmitted={ isSubmitted }/>
      </form>
    )
  }
}
