export const isDev = () => process.env.NODE_ENV === 'development'

export const setPageReady = () => {
  setTimeout(() => {
    document.querySelector('body').removeAttribute('style')
  }, 150)
}

export const initStickyLogo = () => {
  const $bureauStickyLogo = document.querySelector('.js__bureauStickyCopy')
  const $firstHeading = document.querySelector('h2')

  const checkStickyLogo = () => {
    if ($bureauStickyLogo.offsetTop > $firstHeading.getBoundingClientRect().top - 30) {
      $bureauStickyLogo.classList.add('is__compact')
    } else {
      $bureauStickyLogo.classList.remove('is__compact')
    }
  }

  checkStickyLogo()
  document.addEventListener('scroll', checkStickyLogo)
  window.addEventListener('resize', checkStickyLogo)
}
