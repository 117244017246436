import './buttonSend.scss'
import { IconTextSend, IconTextSent } from '../icons/icons'

const ButtonSend = ({ isSubmitted }) => (
  <div className="buttonSend">
    <button type="submit" disabled={ isSubmitted }>Отправить</button>

    { isSubmitted ? <IconTextSent/> : <IconTextSend/> }
  </div>
)

export default ButtonSend
